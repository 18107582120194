import * as Utils from '@js/modules/utils';
import I18n from '@js/modules/translations';

import Notification from '@js/components/layouts/notification';

import {pushError} from '@js/actions/errorManagement';

import MapRequest from '@js/modules/maps/mapRequest';
import MapInterface from '@js/modules/maps/mapInterface';

// import PWAManager from '@js/modules/pwaManager';

import {fetchTimeout} from '@js/components/modules/constants';

import StorageService from '@js/modules/storage';
import AnalyticsService from '@js/modules/analyticsService';

import Polyline from '@js/modules/maps/polyline';
import Simplify from '@js/modules/maps/simplify';

import markerStepIcon from '@/images/maps/marker-step.png';
import markerPauseIcon from '@/images/maps/marker-pause-edit.png';
import markerStartIcon from '@/images/maps/marker-start-play.png';
import markerDistanceIcon from '@/images/maps/distance-marker.png';


export default class BuildMap extends MapInterface {
    _mapModel = {
        // Initial map points
        initialPoints: undefined,

        // activity polyline
        activityPolylines: [],

        // snapToRoads
        snapToRoads: false,
        isComputingRoad: false,
        previousRoadComputedPoints: 0,
        routingService: null,

        // activity points
        linePoints: [],

        // all direction arrows
        directionArrows: [],

        // all ghost points
        ghostPoints: [],

        // all distance Markers
        distanceMarkers: [],

        // Marker ID currently dragging
        draggingMarkerId: null,

        // Prevent double click on marker and on map at the same time
        markerEventClicked: false,

        // undo actions
        snapShotStack: [],

        // If a pop-up is already opened
        hasPopup: false,

        // Editing ride ID
        rideId: undefined,

        // Edition mode (can add points and lines)
        editMode: true,

        // callback functions for edit
        mapEdited: [],

        // callback functions for reset
        mapReset: [],

        // distance for callback functions (in km)
        distance: 0,

        // view point for checking a specific point on map (ex. chart hover)
        viewPointMarker: undefined,

        // object for LatLngs by distance traveled (for highcharts hover values)
        pointsByDistanceTraveled: {},

        // PATCHME: utility?
        // // Back to home
        // isReturnedToHome: false,

        // Distance updated
        distanceUpdated: undefined,

        // Total duration
        durationSeconds: 0,

        // Routing
        routingRouteType: 'hike',
        // Set when computing the first point (changed to Mapbox if unavailable)
        currentRoutingService: undefined
    };

    constructor(modules, params) {
        params.mapMarkers = [
            {
                id: 'ghost-marker',
                url: markerStepIcon,
                properties: {
                    opacity: 0,
                    size: 0.5
                }
            },
            {
                id: 'step-marker',
                url: markerStepIcon,
                properties: {
                    size: 0.7
                }
            },
            {
                id: 'pause-marker',
                url: markerPauseIcon,
                properties: {
                    size: 0.7
                }
            },
            // {
            //     id: 'distance-marker',
            //     url: `${window.settings.assets_url}/maps/distance-marker.png`,
            //     properties: {
            //         size: 1,
            //         opacity: 1
            //     }
            // },
            {
                id: 'start-marker',
                url: markerStartIcon,
                properties: {
                    size: 0.5
                }
            }
        ];

        super(modules, params);

        if (this.hasModule('HELPER') && this.plugHelperEvents) {
            this.plugHelperEvents();
        }

        if (params.initialPoints) {
            this._mapModel.initialPoints = params.initialPoints;

            if (this.hasModule('RIDE_STORE')) {
                this.storeRide();
            }
        } else if (params.reusePreviousTracks && this.hasModule('RIDE_STORE')) {
            this._mapModel.initialPoints = this.getStoredRide();

            if (this._mapModel.initialPoints && this._mapModel.initialPoints.length > 0) {
                if (this.hasModule('HELPER') && this.plugHelperEvents) {
                    this.displayMapHelper(I18n.t('js.maps.helpers.stored_ride'));
                }
            }
        }

        if (Utils.isPresent(params.snapToRoads)) {
            this._mapModel.snapToRoads = params.snapToRoads;
        }

        if (Utils.isPresent(params.editMode)) {
            this._mapModel.editMode = params.editMode;
        }

        if (Utils.isPresent(params.rideId)) {
            this._mapModel.rideId = params.rideId;
        }

        this._mapModel.$styleButtonContainers = this._model.$mapId.querySelector('#map-controls-container');

        if (this.hasModule('TOOLBAR') && this.initializeToolbar) {
            this.initializeToolbar();
            if (!this._mapModel.initialPoints || this._mapModel.initialPoints.length === 0) {
                this.disableToolbarButtons();
            }
        }

        this.onMapLoaded(() => {
            this.addMapTrackLine(this._model.mapTrackLineName);

            this._mapInitialSetup();

            if (this._mapModel.editMode) {
                this.onMapEdited(() => {
                    this._drawDirectionArrows();
                    // this._drawGhostPoints();
                });
            }

            if (params.useSearchMarker && params.initialLocation) {
                this.displaySearchMarker(params.initialLocation[0], params.initialLocation[1]);
            }

            if (this.hasModule('HELPER') && this.plugHelperEvents) {
                this.displayMapHelper(window.screen.width < 1024
                        ?
                        I18n.t('js.maps.helpers.mobile.first_point')
                        :
                        I18n.t('js.maps.helpers.desktop.first_point')
                    , {limitDisplay: true});
            }

            if (this._mapModel.rideId && this.hasModule('RIDE_POIS') && this.fetchRidePOIs) {
                this.fetchRidePOIs(this._mapModel.rideId);
            }

            this._model.map.on('zoomstart', () => {
                this._model.previousZoom = this._model.map.getZoom();
            });

            this._model.map.on('zoomend', () => {
                this._saveMapPosition();

                if (!this.isDataChangedOnZoom(this._model.map.getZoom(), this._model.previousZoom)) {
                    return;
                }

                this._redrawKeyPoints({refresh: true});
                this._drawDirectionArrows();
            });

            if (this._mapModel.editMode) {
                // Don't add point if double click or double tapping
                this._model.map.doubleClickZoom.enable();

                // Add a listener for the click event
                this._model.map.on('click', this._addKeyPointDebounce);

                this._model.map.on('dblclick', () => this._model.isDoubleClick = true);

                this._addKeyPointEventHandlers();
            }
        });
    }

    goToLocation = (bounds, center, options = {}) => {
        if (!this._model.map) {
            return;
        }

        // Use first bounds then center
        var lat;
        var lng;
        bounds = this._buildBounds(bounds);

        if (Utils.is()
            .isObject(center)) {
            lat = center.lat;
            lng = center.lng;
        } else {
            lat = center[1];
            lng = center[0];
        }

        if ((!lat || !lng) && !bounds) {
            return;
        }

        // Use search marker as starting point
        if (options.startMarker && !this._mapModel.isComputingRoad && !this._mapModel.linePoints.length) {
            if (this.hasModule('TOOLBAR')) {
                this.activateToolbarButtons();
            }

            this._mapModel.linePoints.push(new MapInterface.PointInfo({
                lat: lat,
                lng: lng
            }, null));

            this._redrawKeyPoints();
            this._mapEditedFuncs();

            // this._model.startingPointAddress = this._model.$locationSearch.val().split(', ').slice(0, 2).join(', ');
        } else if (options.showPositionMarker) {
            this.displaySearchMarker(lat, lng);
        }

        var moveToBounds = null;
        if (bounds) {
            moveToBounds = this.fitBounds(bounds, {zoom: this._model.searchZoom});
        }

        if (center || !moveToBounds) {
            this.flyTo({
                lat: lat,
                lng: lng
            }, this._model.searchZoom);
        }
    };

    onDistanceUpdated = (callback) => {
        this._mapModel.distanceUpdated = callback;
    };

    onMapEdited = (callback) => {
        this._mapModel.mapEdited.push(callback);
    };

    setSnapToRoad = (value) => {
        this._mapModel.snapToRoads = value;
    };

    setRouteType = (routeType) => {
        this._mapModel.routingRouteType = routeType;
    };

    backToHome = () => {
        if (this._mapModel.isComputingRoad || this._mapModel.linePoints.length < 2) {
            return;
        }

        this._pushUndoSnapShot();

        const firstLinePointCoordinates = this._mapModel.linePoints[0].latLng;

        this._addKeyPointAtClickLoc(firstLinePointCoordinates);

        if (this.hasModule('RIDE_STORE')) {
            this.storeRide(this._mapModel.linePoints);
        }

        if (this.hasModule('TOOLBAR')) {
            this.disableBackToHome();
        }
    };

    hasActivityData = () => {
        return this._mapModel.linePoints.length > 0;
    };

    hasOnlyOnePoint = () => {
        return this._mapModel.linePoints.length === 1;
    };

    undo = () => {
        if (this._mapModel.snapShotStack.length !== 0) {
            this._revertToSnapshot();
        }

        if (this._mapModel.distanceUpdated) {
            this._mapModel.distanceUpdated(this._mapModel.distance);
        }

        if (this.hasModule('TOOLBAR') && !this.hasActivityData()) {
            this.disableToolbarButtons();
        }

        if (this.hasModule('RIDE_STORE')) {
            this.storeRide(this._mapModel.linePoints);
        }
    };

    clearMap = (callback, options = {}) => {
        if (!this.hasActivityData()) {
            return;
        }

        if (this.alertPopup && !options.noPopup) {
            this.alertPopup.fire({
                title: I18n.t('js.maps.clear_map.title'),
                text: I18n.t('js.maps.clear_map.text'),
                icon: 'warning',
                showCancelButton: true,
                cancelButtonText: I18n.t('js.helpers.buttons.cancel'),
                confirmButtonText: I18n.t('js.maps.clear_map.confirm')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        // this.reset();
                        this.clear();
                        this._redrawKeyPoints();
                        this._mapEditedFuncs();

                        if (this.hasModule('TOOLBAR')) {
                            this.disableToolbarButtons();
                        }

                        if (typeof callback === 'function') {
                            callback();
                        }
                    }
                });
        } else {
            this.clear();
            this._redrawKeyPoints();
            this._mapEditedFuncs();

            if (this.hasModule('TOOLBAR')) {
                this.disableToolbarButtons();
            }

            if (typeof callback === 'function') {
                callback();
            }
        }
    };

    reset = (action) => {
        if (!Utils.isPresent(this._model.map)) {
            return;
        }

        // just undo everything.
        if (this._mapModel.snapShotStack.length > 0) {
            this._revertToSnapshot(0);
        }

        if (this._mapModel.mapReset.length > 0) {
            for (var i = this._mapModel.mapReset.length - 1; i >= 0; i--) {
                this._mapModel.mapReset[i]();
            }
        }

        this._mapModel.mapReset = [];

        // Call edited callbacks
        if (!(action && action === 'clear')) {
            this._mapEditedFuncs();
        }
        if (this._mapModel.distanceUpdated) {
            this._mapModel.distanceUpdated(this._mapModel.distance);
        }

        if (this.hasModule('RIDE_STORE')) {
            this.storeRide(this._mapModel.linePoints);
        }
    };

    clear = () => {
        if (!Utils.isPresent(this._model.map)) {
            return;
        }

        // kill all the things
        this.reset('clear');
        this._clearKeyPoints({clearAll: true});
        this._removeDistanceMarkers();

        this.resetMapSource(this._model.mapTrackLineName);

        this._mapModel.activityPolylines = [];
        this._mapModel.keyPoints = [];
        this._mapModel.linePoints = [];
        this._mapModel.distanceMarkers = [];
        this._mapModel.distance = 0;
        this._mapModel.previousRoadComputedPoints = 0;
        this._mapModel.draggingMarkerId = null;
        // PATCHME: utility?
        // this._mapModel.isReturnedToHome = false;
        if (this._mapModel.distanceUpdated) {
            this._mapModel.distanceUpdated(this._mapModel.distance);
        }

        if (this.hasModule('RIDE_STORE')) {
            this.storeRide();
        }
    };

    clearSavedData = () => {
        if (this.hasModule('RIDE_STORE')) {
            this.storeRide();
        }
    };

    staticMapUrl = () => {
        var overlay = '';
        var size = [600, 500];
        var padding = 100;
        var strokeWidth = 5;
        var lineOpacity = 0.85;
        var mapPosition = 'auto';

        if (Utils.isPresent(this._mapModel.activityPolylines)) {
            var coordinates = this._mapModel.activityPolylines[0];
            var polylinesLength = coordinates.length;

            var fullPoints = [];
            for (var coordinateIndex = 0; coordinateIndex < coordinates.length; coordinateIndex++) {
                fullPoints.push(this._model.map.project(coordinates[coordinateIndex]));
            }

            var simplifiedPoints = Simplify(fullPoints, polylinesLength > 200 ? Math.round(polylinesLength / 50) : 2.0);

            var latLngs = [];
            for (var pointIndex = 0; pointIndex < simplifiedPoints.length; pointIndex++) {
                var latLng = this._model.map.unproject(simplifiedPoints[pointIndex]);
                latLngs.push([latLng.lat, latLng.lng]);
            }

            var simplifiedPolyline = Polyline.encode(latLngs, 5);

            overlay = 'path-' + strokeWidth + '+' + this._model.mapTrackLineColor.substring(1) + '-' + lineOpacity + '(' + encodeURIComponent(simplifiedPolyline) + ')';
        } else {
            return null;
        }

        return MapRequest.mapboxStaticMapUrl + '/' +
            overlay + '/' +
            mapPosition + '/' +
            size.join('x') +
            '?padding=' + padding +
            '&attribution=false&logo=false' +
            '&access_token=' + window.settings.mapbox_key;
    };

    serializePoints = () => {
        // output format:
        var serializeString = '';

        for (var i = 0; i < this._mapModel.linePoints.length; i++) {
            var currentLinePoint = this._mapModel.linePoints[i];
            var deltaDistance = 0;
            var deltaTime = currentLinePoint.deltaTime;
            var prevLinePoint;
            if (i !== 0) {
                prevLinePoint = this._mapModel.linePoints[i - 1];
                if (prevLinePoint.deltaPause === 0) {
                    deltaDistance = currentLinePoint.deltaDistance || this.distHaversine(this._mapModel.linePoints[i - 1].latLng, currentLinePoint.latLng);
                }

                if (currentLinePoint.deltaTime === 0 && this._mapModel.durationSeconds !== 0) {
                    deltaTime = this._mapModel.durationSeconds / (this._mapModel.linePoints.length - 1);
                }
            }

            var pointInfoType;
            if (i === 0) {
                pointInfoType = 'StartPoint';
            } else if (i === this._mapModel.linePoints.length - 1) {
                pointInfoType = 'EndPoint';
            } else if (currentLinePoint.deltaPause > 0) {
                pointInfoType = 'ResumePoint';
            } else if (this._mapModel.linePoints[i + 1].deltaPause > 0) {
                pointInfoType = 'PausePoint';
            } else {
                pointInfoType = 'ManualPoint';
            }

            // Serialized structure (from PointInfo):
            // 0 : point type (StartPoint, EndPoint, ResumePoint, PausePoint, ManualPoint)
            // 1 : latitude
            // 2 : longitude
            // 3 : elevation
            // 4 : delta distance with previous point
            // 5 : delta time with previous point
            // 6 : delta pause with previous point
            // 7 : datetime / timestamp
            // 8 : speed
            // 9 : heart rate

            if (currentLinePoint.heartRate || currentLinePoint.speed || currentLinePoint.datetime || currentLinePoint.deltaPause) {
                serializeString += [
                    pointInfoType,
                    parseFloat(currentLinePoint.latLng.lat.toFixed(5)),
                    parseFloat(currentLinePoint.latLng.lng.toFixed(5)),
                    currentLinePoint.elevation,
                    deltaDistance,
                    deltaTime,
                    currentLinePoint.deltaPause,
                    currentLinePoint.datetime,
                    currentLinePoint.speed,
                    currentLinePoint.heartRate
                ].join(',') + ';';
            } else {
                serializeString += [
                    pointInfoType,
                    parseFloat(currentLinePoint.latLng.lat.toFixed(5)),
                    parseFloat(currentLinePoint.latLng.lng.toFixed(5)),
                    currentLinePoint.elevation,
                    deltaDistance
                ].join(',') + ';';
            }
        }

        return serializeString;
    };

    updatePointsWithElevation = (elevationPoints) => {
        if (elevationPoints.length !== this._mapModel.linePoints.length) {
            return;
        }

        for (var i = 0; i < this._mapModel.linePoints.length; i++) {
            var currentLinePoint = this._mapModel.linePoints[i];

            if (elevationPoints[i] && (elevationPoints[i].elevation || elevationPoints[i].elevation === 0)) {
                if (currentLinePoint.latLng.lat.toFixed(5) === elevationPoints[i].lat.toFixed(5) && currentLinePoint.latLng.lng.toFixed(5) === elevationPoints[i].lng.toFixed(5)) {
                    currentLinePoint.elevation = elevationPoints[i].elevation;
                }
            }
        }
    };

    importTrack = (type, importedTracks, callback) => {
        const importTrackCallback = (newTrackData, isCombined, importCallback) => {
            this.clear();

            if (isCombined) {
                this._mapModel.initialPoints = this._mapModel.initialPoints.concat(newTrackData);
            } else {
                this._mapModel.initialPoints = newTrackData;
            }

            this._model.mapBounds = null;

            this._setupPoints();
            this._redrawKeyPoints();
            this._redrawTripLine();
            this._fitMapBounds();
            if (this.hasModule('TOOLBAR')) {
                this.activateToolbarButtons();
            }

            if (importCallback) {
                importCallback(isCombined, this._mapModel.distance);
            }
        };

        if (type === 'ride' && importedTracks) {
            if (this._mapModel.linePoints.length > 0) {
                this.alertPopup.fire({
                    icon: 'warning',
                    showDenyButton: true,
                    showCancelButton: true,
                    title: I18n.t('js.maps.import.title'),
                    confirmButtonText: I18n.t('js.maps.import.replace'),
                    denyButtonText: I18n.t('js.maps.import.combine'),
                    cancelButtonText: I18n.t('js.helpers.buttons.cancel'),
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            // Replace current track
                            importTrackCallback(importedTracks, false, callback);
                        } else if (result.isDenied) {
                            // Combine with current track
                            importTrackCallback(importedTracks, true, callback);
                        }
                    });
            } else {
                importTrackCallback(importedTracks, false, callback);
            }
        }
    };

    /* Private Methods
    ******************** */
    _mapInitialSetup = () => {
        if (Utils.isPresent(this._mapModel.initialPoints)) {
            // establish key points to edit with
            this._setupPoints();

            // draw trip polyline, add to map
            this._redrawTripLine();

            this._fitMapBounds();
        }
    };

    _setupPoints = () => {
        var i;

        this._mapModel.linePoints = [];

        // Draw polylines points
        if (this._mapModel.initialPoints.length > 0) {
            // var j = -1;
            for (i = 0; i < this._mapModel.initialPoints.length; i++) {
                if (this._mapModel.initialPoints[i].type === 'PoiPoint') {
                    continue;
                    // } else {
                    //     j++;
                }

                var computed = Utils.isPresent(this._mapModel.initialPoints[i].computed);
                var deltaDistance = Utils.isEmpty(this._mapModel.initialPoints[i].deltaDistance) ? 0 : this._mapModel.initialPoints[i].deltaDistance;
                var deltaTime = Utils.isEmpty(this._mapModel.initialPoints[i].deltaTime) ? 0 : this._mapModel.initialPoints[i].deltaTime;
                var deltaPause = Utils.isEmpty(this._mapModel.initialPoints[i].deltaPause) ? 0 : this._mapModel.initialPoints[i].deltaPause;
                var datetime = this._mapModel.initialPoints[i].datetime;
                var elevation = this._mapModel.initialPoints[i].elevation;
                var speed = this._mapModel.initialPoints[i].speed;
                var heartRate = this._mapModel.initialPoints[i].heartRate;
                this._mapModel.linePoints.push(new MapInterface.PointInfo(
                    {
                        lat: this._mapModel.initialPoints[i].latitude,
                        lng: this._mapModel.initialPoints[i].longitude
                    },
                    null, // keyPoint
                    {
                        computed: computed, // i !== 0 || i !== this._mapModel.initialPoints.length - 1,
                        deltaDistance: deltaDistance,
                        deltaTime: deltaTime,
                        deltaPause: deltaPause,
                        datetime: datetime,
                        elevation: elevation,
                        speed: speed,
                        heartRate: heartRate
                    }
                ));
            }
        }

        // if we're in edit mode, setup key points with events
        this._redrawKeyPoints();
    };

    // PATCHME: utility?
    // _addMarkersPointEventHandlers = (markerPoint, markerInfo, markerIndex) => {
    //     markerPoint.marker.getElement()
    //         .addEventListener('click', () => {
    //             if (this._model.searchMarker) {
    //                 this._model.searchMarker.remove();
    //             }
    //         });
    //
    //     markerPoint.marker.getElement()
    //         .addEventListener('contextmenu', () => {
    //             this._mapEditedFuncs();
    //         });
    // };

    _redrawKeyPoints = (options) => {
        options ||= {};

        // clear key points
        this._clearKeyPoints();

        var thisLinePoint;
        var markerName;
        var mapMarkers = {};
        var stepMarkerInterval = this.getZoomDictionary(this._model.map.getZoom()).stepDist;

        // loop through, if the point should be a keypoint, set the keypoint variable and add to map
        var distanceSinceLastKeyPoint = 0;
        var distanceWithEndKeyPoint = 0;
        for (var linePointIndex = 0; linePointIndex < this._mapModel.linePoints.length; linePointIndex++) {
            thisLinePoint = this._mapModel.linePoints[linePointIndex];

            if (linePointIndex === 0) {
                if (options.refresh) {
                    continue;
                }

                // set start point options
                markerName = 'start-marker';
            } else if (linePointIndex === this._mapModel.linePoints.length - 1) {
                if (options.refresh) {
                    continue;
                }

                // set finish point options
                markerName = 'finish-marker';
            } else if (this._mapModel.linePoints[linePointIndex + 1].deltaPause > 0) {
                // set pause point options
                markerName = 'pause-marker';
            } else if (thisLinePoint.deltaPause > 0) {
                // set resume point options
                markerName = 'resume-marker';
            } else if (thisLinePoint.computed) {
                // if point is computed, display points accordingly to the current zoom
                var prevLineComputedPoint = this._mapModel.linePoints[linePointIndex - 1];
                distanceSinceLastKeyPoint += this.distHaversine(prevLineComputedPoint.latLng, thisLinePoint.latLng);
                distanceWithEndKeyPoint = this.distHaversine(thisLinePoint.latLng, this._mapModel.linePoints[this._mapModel.linePoints.length - 1].latLng);

                // if more than X meters have passed since last key point, make a normal key point.
                // otherwise, simply kick the distance since last key point variable
                if (stepMarkerInterval && distanceSinceLastKeyPoint > stepMarkerInterval && distanceWithEndKeyPoint > 100) {
                    markerName = 'step-marker';
                    distanceSinceLastKeyPoint = 0;
                } else {
                    markerName = null;
                }
            } else {
                // normal point need to be present when dragging the siblings points
                markerName = 'step-marker';
            }

            if (markerName && thisLinePoint.latLng) {
                thisLinePoint.keyPoint = {
                    type: 'Feature',
                    properties: {
                        markerId: markerName,
                        linePointIndex: linePointIndex,
                        draggable: this._mapModel.editMode
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [thisLinePoint.latLng.lng, thisLinePoint.latLng.lat]
                    }
                };
                mapMarkers[markerName] = (mapMarkers[markerName] || []).concat(thisLinePoint.keyPoint);
            }
        }

        Object.keys(mapMarkers)
            .forEach((markerType) => {
                this.updateSource(markerType, {
                    type: 'FeatureCollection',
                    features: mapMarkers[markerType]
                });
            });

        if (this._mapModel.linePoints.length > 1 && !options.refresh) {
            this._redrawTripLine();
        }
    };

    _redrawTripLine = () => {
        var linePointIndex;

        // remove markers
        this._removeDistanceMarkers();

        // clear pointsByDistanceTraveled object (used by highCharts)
        this._mapModel.pointsByDistanceTraveled = {};

        // set initial 'total Distance' variable
        var totalDistance = 0;

        // remove all activity line polylines from the map
        this.resetMapSource(this._model.mapTrackLineName);

        // clear both arrays for re-use
        this._mapModel.activityPolylines = [];

        // distance variables for lines
        var lastLineStartPointIndex = 0;
        var lastLinePointTotalDistance = 0;

        // what distance marker we are currently on
        var markerCount = 0;
        for (linePointIndex = 0; linePointIndex < this._mapModel.linePoints.length; linePointIndex++) {
            var thisLegDist = 0;

            // if we have reached a pause point OR the last point (finish) draw this segment and process
            if (linePointIndex === this._mapModel.linePoints.length - 1 /* || this._mapModel.linePoints[linePointIndex + 1].deltaPause > 0 */) {
                // draw this line segment
                var nextLine = [];
                for (var j = lastLineStartPointIndex; j <= linePointIndex; j++) {
                    var thisLinePointLatLng = this._mapModel.linePoints[j].latLng;
                    var prevLinePointLatLng;

                    nextLine.push(this._mapModel.linePoints[j].latLng);

                    if (j !== lastLineStartPointIndex) {
                        prevLinePointLatLng = this._mapModel.linePoints[j - 1].latLng;

                        if (this._mapModel.linePoints[linePointIndex].deltaDistance) {
                            thisLegDist = this._mapModel.linePoints[j].deltaDistance;
                            totalDistance += thisLegDist;
                        } else {
                            thisLegDist = Utils.flooredNum(this.distHaversine(prevLinePointLatLng, thisLinePointLatLng), 6);
                            totalDistance += thisLegDist;
                        }
                    }

                    // Check if max distance is reached
                    // PATCHME: for edit mode
                    // if (this._mapModel.mode !== localMap.mode.EDIT) {
                    if (totalDistance >= this._model.mapMaxDistance) {
                        this.leaveFullscreen();

                        if (this.alertPopup) {
                            this.alertPopup.fire({
                                title: I18n.t('js.maps.errors.distance.title'),
                                text: I18n.t('js.maps.errors.distance.text', {distance: parseInt(this._model.mapMaxDistance) / 1000}),
                                icon: 'warning'
                            })
                                .then(function (event) {
                                    if (event.isConfirmed) {
                                        // Remove last point
                                        this.undo();
                                    }
                                }.bind(this));
                        }

                        return;
                    }

                    if (this._model.mapMaxDistance && totalDistance >= this._model.mapMaxDistance) {
                        this.leaveFullscreen();

                        if (this._model.showProductPopup) {
                            this._model.showProductPopup('max_distance');
                        }

                        return;
                    }
                    // }

                    // handle the total distance stamp for Highcharts point mapping
                    var traveledDistance = totalDistance;
                    if (this._model.distanceUnits === 'mi') {
                        traveledDistance /= 1.609344;
                    }

                    this._mapModel.pointsByDistanceTraveled[Math.floor(traveledDistance)] = thisLinePointLatLng;

                    // Are we 1 distance unit (or more) greater? try to draw a distance marker
                    var curDist = traveledDistance / 1000;
                    if (curDist >= markerCount + 1) {
                        var markersToAdd = Math.floor(curDist) - markerCount;
                        for (var k = 0; k < markersToAdd; k++) {
                            // kick marker number
                            markerCount++;

                            // if zoomed in far enough, show all individual mile markers
                            // if not zoomed in past 13, show every 5 if at 12
                            // if not zoomed in past 12, show every 10
                            if ((this._model.map.getZoom() >= 13) || ((this._model.map.getZoom() >= 12) && (markerCount % 5 === 0)) || (markerCount % 10 === 0)) {
                                // lat lng of distance marker to place
                                var distancePointLat;
                                var distancePointLng;

                                // find lat lng
                                var distanceToDistMarker = markerCount - lastLinePointTotalDistance;
                                var percentageDist;
                                if (this._model.distanceUnits === 'mi') {
                                    percentageDist = distanceToDistMarker / (thisLegDist / 1000 / 1.609344);
                                } else {
                                    percentageDist = distanceToDistMarker / (thisLegDist / 1000);
                                }
                                distancePointLat = prevLinePointLatLng.lat + ((thisLinePointLatLng.lat - prevLinePointLatLng.lat) * percentageDist);
                                distancePointLng = prevLinePointLatLng.lng + ((thisLinePointLatLng.lng - prevLinePointLatLng.lng) * percentageDist);

                                var textSizeChange = '';
                                if (markerCount > 99) {
                                    textSizeChange = ' style="font-size: 9px;"';
                                }

                                // Set up icon + marker
                                var distanceMarker = new MapInterface.MarkerPoint(
                                    this.buildMarker('distance-unit-marker', {
                                        lat: distancePointLat,
                                        lng: distancePointLng
                                    }, {
                                        clickable: false,
                                        draggable: false,
                                        markerContent: '<div class="number"' + textSizeChange + '>' + markerCount + '</div><div class="dist-unit">' + this._model.distanceUnits + '</div></div>',
                                        markerImage: markerDistanceIcon
                                    }),
                                    markerCount
                                );

                                // push to distance marker array
                                this._mapModel.distanceMarkers.push(distanceMarker);

                                // draw this distance marker on map
                                distanceMarker.marker.addTo(this._model.map);
                            }
                        }
                    }

                    // update last line point distance stamp
                    lastLinePointTotalDistance = curDist;
                }

                // draw lines on map and thumbnails in the set color
                var thisLine = {
                    type: 'Feature',
                    geometry: {
                        type: 'LineString',
                        coordinates: nextLine.map((lngLat) => [lngLat.lng, lngLat.lat])
                    }
                };

                this.updateSource(this._model.mapTrackLineName, thisLine);

                this._mapModel.activityPolylines.push(thisLine.geometry.coordinates);

                // kick last line start point index (start at the next point, the resume)
                lastLineStartPointIndex = linePointIndex + 1;
            }
        }

        // distance to km
        this._mapModel.distance = Utils.flooredNum(totalDistance, 6) / 1000;

        // run distance updated function stack
        if (this._mapModel.distanceUpdated) {
            this._mapModel.distanceUpdated(this._mapModel.distance);
        }
    };

    _removeDistanceMarkers = () => {
        // Start by removing the ghost points already there
        for (var i = 0; i < this._mapModel.distanceMarkers.length; i++) {
            this._mapModel.distanceMarkers[i].marker.remove();
        }

        this._mapModel.distanceMarkers = [];
    };

    _drawDirectionArrows = () => {
        this._removeDirectionArrows();

        // how often SHOULD we be placing direction arrows?
        var directionArrowInterval = this.getZoomDictionary(this._model.map.getZoom()).directionArrow;
        if (!directionArrowInterval) {
            return;
        }

        // draw a fake point in between every key point to allow user to insert a new point here.
        if (this._mapModel.linePoints.length > 1) {
            // variable for distance since last direction arrow
            var distanceSinceLastDirectionArrow = 0;

            // after placing a direction arrow, how much distance is left over (so we can place correctly after the next invisible point)
            var carryoverDistance = 0;
            var lastKeyPointIndex = 0;
            for (var linePointIndex = 0; linePointIndex < this._mapModel.linePoints.length; linePointIndex++) {
                var thisLinePoint;
                var prevLinePoint;
                thisLinePoint = this._mapModel.linePoints[linePointIndex];

                // variable for point-to-point distance
                var distanceBetween = 0;
                if (linePointIndex > 0) {
                    prevLinePoint = this._mapModel.linePoints[linePointIndex - 1];
                    distanceBetween = this.distHaversine(prevLinePoint.latLng, thisLinePoint.latLng);
                    distanceSinceLastDirectionArrow += distanceBetween;
                }

                // if we've traveled more than the direction arrow interval, find out how many to place
                var directionArrowsToPlace = 0;
                if (distanceSinceLastDirectionArrow > directionArrowInterval) {
                    directionArrowsToPlace = Math.floor(distanceSinceLastDirectionArrow / directionArrowInterval);
                }

                // start with carryover distance
                // move from point A to point B, placing direction arrows at interval specified (remove carryover from last loop iteration)
                // when done, set carryover distance and continue
                if (directionArrowsToPlace > 0) {
                    for (var directionArrowIndex = 1; directionArrowIndex <= directionArrowsToPlace; directionArrowIndex++) {
                        var directionArrowLat;
                        var directionArrowLng;
                        var distanceToNextDirectionArrow = (directionArrowInterval * directionArrowIndex) - carryoverDistance;
                        var percentageBetweenPoints = distanceToNextDirectionArrow / distanceBetween;

                        // we now know at what percentage distance the direction arrow should be placed. Use to put it on the line
                        directionArrowLat = prevLinePoint.latLng.lat + ((thisLinePoint.latLng.lat - prevLinePoint.latLng.lat) * percentageBetweenPoints);
                        directionArrowLng = prevLinePoint.latLng.lng + ((thisLinePoint.latLng.lng - prevLinePoint.latLng.lng) * percentageBetweenPoints);

                        var diffLat = thisLinePoint.latLng.lat - prevLinePoint.latLng.lat;
                        var diffLng = thisLinePoint.latLng.lng - prevLinePoint.latLng.lng;
                        // var center = [thisLinePoint.latLng.lat + diffLat / 2, thisLinePoint.latLng.lng + diffLng / 2];
                        var angle = (360 - ((Math.atan2(diffLat, diffLng) * 57.295779513082) % 360));

                        // create direction arrow object
                        var newDirectionArrow = new MapInterface.DirectionArrow(
                            {
                                type: 'Feature',
                                properties: {
                                    angle: angle,
                                    linePointIndex: linePointIndex,
                                    directionArrowIndex: directionArrowIndex
                                },
                                geometry: {
                                    type: 'Point',
                                    coordinates: [directionArrowLng, directionArrowLat]
                                }
                            },
                            lastKeyPointIndex
                        );

                        // add to array for reference
                        this._mapModel.directionArrows.push(newDirectionArrow);

                        if (directionArrowIndex === directionArrowsToPlace) {
                            // last need
                            carryoverDistance = distanceBetween - (percentageBetweenPoints * distanceBetween);
                            distanceSinceLastDirectionArrow = carryoverDistance;
                        }
                    }
                } else {
                    carryoverDistance += distanceBetween;
                }

                if (thisLinePoint && thisLinePoint.keyPoint !== null) {
                    lastKeyPointIndex = linePointIndex;
                }
            }

            this.updateSource('arrow-marker', {
                type: 'FeatureCollection',
                features: this._mapModel.directionArrows.map((directionArrow) => directionArrow.keyPoint)
            });
        }
    };

    _removeDirectionArrows = () => {
        if (!this._mapModel.directionArrows.length) {
            return;
        }

        this.resetMapSource('arrow-marker');

        this._mapModel.directionArrows = [];
    };

    _manageEventAction = (callback, event) => {
        event.preventDefault();
        event.originalEvent.stopPropagation();

        callback(this._convertPopupCoordinates(event), event.features[0].properties);
    };

    _onSourceEvent = (eventName, sourceIds, callback) => {
        var eventListener = this._manageEventAction.bind(this, callback);

        if (Array.isArray(sourceIds)) {
            sourceIds.forEach((sourceId) => {
                this._model.map.on(eventName, sourceId, eventListener);
            });
        } else {
            this._model.map.on(eventName, sourceIds, eventListener);
        }
    };

    _onSourceLongTouch = (sourceIds, callback) => {
        var longTouchDuration = 500;

        var touchTimeout = null;
        var clearTouchTimeout = () => {
            this._model.isLongTouching = false;
            clearTimeout(touchTimeout);
        };

        if (Array.isArray(sourceIds)) {
            sourceIds.forEach((sourceId) => {
                this._model.map.on('touchstart', sourceId, (event) => {
                    if (event.originalEvent.touches.length > 1) {
                        return;
                    }

                    this._model.isLongTouching = true;

                    var features = event.features;
                    touchTimeout = setTimeout(function (touchEvent) {
                        touchEvent.features = features;
                        this._manageEventAction(callback, touchEvent);
                    }.bind(this, event), longTouchDuration);
                });

                this._model.map.on('touchend', sourceId, clearTouchTimeout);
                this._model.map.on('touchcancel', sourceId, clearTouchTimeout);
                this._model.map.on('touchmove', sourceId, clearTouchTimeout);
            });
        } else {
            this._model.map.on('touchstart', sourceIds, (event) => {
                if (event.originalEvent.touches.length > 1) {
                    return;
                }

                this._model.isLongTouching = true;

                var features = event.features;
                touchTimeout = setTimeout(function (touchEvent) {
                    touchEvent.features = features;
                    this._manageEventAction(callback, touchEvent);
                }.bind(this, event), longTouchDuration);
            });

            this._model.map.on('touchend', sourceIds, clearTouchTimeout);
            this._model.map.on('touchcancel', sourceIds, clearTouchTimeout);
            this._model.map.on('touchmove', sourceIds, clearTouchTimeout);
        }
    };

    _onDragSource = (sourceId, onDragStart, onDragging, onDragEnd, markerDragProperties) => {
        const canvas = this._model.map.getCanvasContainer();
        let markerProperties;
        markerDragProperties ||= {};

        const onDragMove = (event) => {
            var coordinates = event.lngLat;

            canvas.style.cursor = 'grabbing';

            onDragging(sourceId, coordinates, markerProperties);
        };

        const onDragUp = (event) => {
            var coordinates = event.lngLat;

            canvas.style.cursor = '';

            onDragEnd(sourceId, coordinates, markerProperties);

            // Unbind mouse/touch events
            this._model.map.off('mousemove', onDragMove);
            this._model.map.off('touchmove', onDragMove);
        };

        // When the cursor enters a feature in the point layer, prepare for dragging.
        this._model.map.on('mouseenter', sourceId, () => {
            this._model.map.setPaintProperty(sourceId, 'icon-opacity', typeof markerDragProperties.hoverOpacity === 'undefined' ? 1 : markerDragProperties.hoverOpacity);

            canvas.style.cursor = 'move';
        });

        this._model.map.on('mouseleave', sourceId, () => {
            this._model.map.setPaintProperty(sourceId, 'icon-opacity', typeof markerDragProperties.defaultOpacity === 'undefined' ? 1 : markerDragProperties.defaultOpacity);

            canvas.style.cursor = '';
        });

        this._model.map.on('mousedown', sourceId, (event) => {
            // Prevent move if not left click
            if (event.originalEvent.button !== 0 || this._model.isLongTouching) {
                return;
            }

            // Prevent the default map drag behavior
            event.preventDefault();
            event.originalEvent.stopPropagation();

            canvas.style.cursor = 'grab';

            markerProperties = event.features[0].properties;
            onDragStart(sourceId, event.lngLat, markerProperties);
            this._model.map.on('mousemove', onDragMove);
            this._model.map.once('mouseup', onDragUp);
        });

        this._model.map.on('touchstart', sourceId, (event) => {
            if (event.points.length !== 1 || this._model.isLongTouching) {
                return;
            }

            // Prevent the default map drag behavior.
            event.preventDefault();
            event.originalEvent.stopPropagation();

            markerProperties = event.features[0].properties;
            onDragStart(sourceId, event.lngLat, markerProperties);
            this._model.map.on('touchmove', onDragMove);
            this._model.map.once('touchend', onDragUp);
        });
    };

    _addKeyPointDebounce = Utils.debounce((event) => {
        if (this._model.isDoubleClick) {
            this._model.isDoubleClick = false;
            return;
        }

        // Avoid double click
        this._addKeyPointAtClickLoc(event.lngLat);
    }, 200);

    _addKeyPointEventHandlers = () => {
        this._onSourceEvent('click', ['start-marker', 'step-marker', 'finish-marker'], (coordinates, markerProperties) => {
            this._addLinePoint(coordinates, markerProperties);
        });

        this._onSourceEvent('contextmenu', ['start-marker', 'step-marker', 'finish-marker'], (coordinates, markerProperties) => {
            this._removeLinePoint(coordinates, markerProperties);
        });

        this._onSourceLongTouch(['start-marker', 'step-marker', 'finish-marker'], (coordinates, markerProperties) => {
            this._removeLinePoint(coordinates, markerProperties);
        });

        ['start-marker', 'step-marker', 'finish-marker'].forEach((markerId) => {
            this._onDragSource(
                markerId,
                this._onMarkerDragStart.bind(this),
                this._onMarkerDragging.bind(this),
                this._onMarkerDragEnd.bind(this)
            );
        });

        // this.onDragSource(
        //     'ghost-marker',
        //     this._onGhostPointDragStart.bind(this),
        //     this._onGhostPointDragging.bind(this),
        //     this._onGhostPointDragEnd.bind(this),
        //     {
        //         defaultOpacity: 0,
        //         hoverOpacity: 0.7
        //     }
        // );
    };

    _addLinePoint = (coordinates, markerProperties) => {
        if (typeof markerProperties.linePointIndex === 'undefined') {
            return;
        }

        // Merge start and end point if click on first point
        if (markerProperties.linePointIndex !== 0
            // PATCHME: for edit mode
            // || (this._model.mode !== localMap.mode.NEW && this._model.mode !== localMap.mode.EDIT)
        ) {
            return;
        }

        if (this._mapModel.editMode) {
            return;
        }

        this._addKeyPointAtClickLoc(coordinates);
    };

    _removeLinePoint = (coordinates, markerProperties) => {
        this._pushUndoSnapShot();

        var linePointIndex = markerProperties.linePointIndex;
        markerProperties.prevKeyPointIndex = this._getPrevKeyPointIndex(linePointIndex, true);
        markerProperties.nextKeyPointIndex = this._getNextKeyPointIndex(linePointIndex, true);
        var deleteCurrentPointAsWell = (linePointIndex === markerProperties.nextKeyPointIndex || linePointIndex === markerProperties.prevKeyPointIndex);

        this._removeLinePointsBetween(markerProperties.prevKeyPointIndex, markerProperties.nextKeyPointIndex);
        var isFirstPoint = markerProperties.markerId === 'start-marker' || (linePointIndex === 0);
        var isLastPoint = markerProperties.markerId === 'finish-marker' || (this._mapModel.linePoints.length - 1 === linePointIndex);

        linePointIndex -= (linePointIndex - markerProperties.prevKeyPointIndex);

        if (deleteCurrentPointAsWell) {
            if (isFirstPoint) {
                this._mapModel.linePoints.splice(0, 1);
            } else if (isLastPoint) {
                this._mapModel.linePoints.splice(this._mapModel.linePoints.length - 1, 1);
            } else {
                this._mapModel.linePoints.splice(linePointIndex, 1);
            }
        }

        markerProperties.linePointIndex = linePointIndex;

        this._redrawKeyPoints();

        if (!this._mapModel.linePoints.length) {
            this.resetMapSource('start-marker');
        } else if (this._mapModel.linePoints.length === 1) {
            this._redrawTripLine();
        }

        this._mapEditedFuncs();

        if (this.hasModule('RIDE_STORE')) {
            this.storeRide(this._mapModel.linePoints);
        }
    };

    _removeLinePointsBetween = (startIndex, endIndex) => {
        if (startIndex == null || typeof startIndex === 'undefined' ||
            endIndex == null || typeof endIndex === 'undefined') {
            return [];
        }
        var totalToDelete = endIndex - startIndex - 1;
        var deletedPoints = this._mapModel.linePoints.splice(startIndex + 1, totalToDelete);
        return deletedPoints;
    };

    _onMarkerDragStart = (markerId, coordinates, markerProperties) => {
        // Prevent double dragging
        if (this._mapModel.draggingMarkerId && this._mapModel.draggingMarkerId !== markerId) {
            return;
        }
        this._mapModel.draggingMarkerId = markerId;
        this._mapModel.previousRoadComputedPoints = 0;

        this._pushUndoSnapShot();

        // remove direction arrows
        this._removeDirectionArrows();

        // event handlers for drag start, drag end, drag
        var linePointIndex = markerProperties.linePointIndex;
        // get next keypoint line point index, previous keypoint line point index
        markerProperties.prevKeyPointIndex = this._getPrevKeyPointIndex(linePointIndex);
        markerProperties.nextKeyPointIndex = this._getNextKeyPointIndex(linePointIndex);

        if (markerId !== 'start-marker') {
            var deletedPointsBefore;
            var deletedPointsAfter;

            if (markerProperties.prevKeyPointIndex !== null) {
                // delete points before
                deletedPointsBefore = this._removeLinePointsBetween(markerProperties.prevKeyPointIndex, linePointIndex);
                // points have been removed, adjust index references
                linePointIndex -= deletedPointsBefore.length;
                markerProperties.nextKeyPointIndex -= deletedPointsBefore.length;
            }

            if (markerProperties.nextKeyPointIndex !== null) {
                // delete points after
                deletedPointsAfter = this._removeLinePointsBetween(linePointIndex, markerProperties.nextKeyPointIndex);
                // points have been removed, adjust index references
                markerProperties.nextKeyPointIndex -= deletedPointsAfter.length;
            }
        }

        markerProperties.linePointIndex = linePointIndex;

        this.resetMapSource('step-marker');
    };

    _onMarkerDragging = (markerId, coordinates, markerProperties) => {
        // Prevent double dragging
        if (this._mapModel.draggingMarkerId && this._mapModel.draggingMarkerId !== markerId) {
            return;
        }

        var linePointIndex = markerProperties.linePointIndex;
        var thisLinePoint = this._mapModel.linePoints[linePointIndex];
        if (!thisLinePoint) {
            return;
        }

        thisLinePoint.computed = false;

        thisLinePoint.latLng = coordinates;

        if (markerId !== 'step-marker' && this._mapModel.snapToRoads && this.isMapPlannerRouting(this._mapModel.routingService)) {
            var startingPoint;
            var endingPoint;

            if (markerId === 'start-marker') {
                startingPoint = thisLinePoint;
                if (this._mapModel.linePoints.length > 1) {
                    endingPoint = this._mapModel.linePoints[linePointIndex + 1 + this._mapModel.previousRoadComputedPoints];
                }
            } else if (markerId === 'finish-marker') {
                startingPoint = this._mapModel.linePoints[linePointIndex - 1];
                endingPoint = thisLinePoint;
            }

            if (startingPoint && endingPoint) {
                this._getSnapToRoadsRoute(
                    startingPoint.latLng,
                    endingPoint.latLng,
                    null,
                    (pointArray) => {
                        if (typeof pointArray !== 'undefined') {
                            var points = [];
                            for (var routePointIndex = 0; routePointIndex < pointArray.length; routePointIndex++) {
                                points.push(new MapInterface.PointInfo(pointArray[routePointIndex], null, {computed: true}));
                            }

                            if (markerId === 'start-marker') {
                                Array.prototype.splice.apply(this._mapModel.linePoints, [0, this._mapModel.previousRoadComputedPoints || 1].concat(points));
                            } else {
                                Array.prototype.splice.apply(this._mapModel.linePoints, [linePointIndex, this._mapModel.previousRoadComputedPoints + 1].concat(points));
                            }

                            this._mapModel.previousRoadComputedPoints = points.length;
                        }

                        this._redrawKeyPoints();
                    }
                );
            } else {
                this._redrawKeyPoints();
            }
        } else {
            this._redrawTripLine();

            // Update collection with only one point when dragging, display all points when dragging end
            this.updateSource(markerId, {
                type: 'FeatureCollection',
                features: [
                    {
                        type: 'Feature',
                        properties: markerProperties,
                        geometry: {
                            type: 'Point',
                            coordinates: [thisLinePoint.latLng.lng, thisLinePoint.latLng.lat]
                        }
                    }
                ]
            });
        }
    };

    _onMarkerDragEnd = (markerId, coordinates, markerProperties) => {
        // Prevent double dragging
        if (this._mapModel.draggingMarkerId && this._mapModel.draggingMarkerId !== markerId) {
            return;
        }
        this._mapModel.draggingMarkerId = null;
        this._mapModel.previousRoadComputedPoints = 0;

        var linePointIndex = markerProperties.linePointIndex;
        var thisLinePoint = this._mapModel.linePoints[linePointIndex];
        if (!thisLinePoint) {
            return;
        }

        // we are either already set, or need to fill with snap to roads points
        if ((this.isMapPlannerRouting(this._mapModel.routingService) ? markerId === 'step-marker' : true) && this._mapModel.snapToRoads && this._mapModel.linePoints.length > 1 && thisLinePoint) {
            // nothing before starting point / nothing before a resume point
            // nothing after end point / nothing after a pause point
            // otherwise, use this point as waypoint

            var startingPoint;
            var endingPoint;
            var waypoint;
            var callback;
            if (linePointIndex === 0 || thisLinePoint.deltaPause > 0) {
                // this is a start or resume point, only show stuff after
                startingPoint = thisLinePoint.latLng;
                if (this._mapModel.linePoints[markerProperties.nextKeyPointIndex]) {
                    endingPoint = this._mapModel.linePoints[markerProperties.nextKeyPointIndex].latLng;
                }
                waypoint = null;
                callback = (pointArray) => {
                    if (typeof pointArray !== 'undefined') {
                        var points = [];
                        for (var routePointIndex = 0; routePointIndex < pointArray.length; routePointIndex++) {
                            points.push(new MapInterface.PointInfo(pointArray[routePointIndex], null, {computed: true}));
                        }

                        for (var j = 0; j < points.length; j++) {
                            this._mapModel.linePoints.splice(linePointIndex + 1 + j, 0, points[j]);
                        }
                    }

                    this._redrawKeyPoints();
                    this._mapEditedFuncs();
                };
            } else if (linePointIndex === this._mapModel.linePoints.length - 1 ||
                this._mapModel.linePoints[linePointIndex + 1].deltaPause > 0) {
                // this is a finish or pause point, only show stuff before
                if (this._mapModel.linePoints[markerProperties.prevKeyPointIndex]) {
                    startingPoint = this._mapModel.linePoints[markerProperties.prevKeyPointIndex].latLng;
                }
                endingPoint = thisLinePoint.latLng;
                waypoint = null;
                callback = (pointArray) => {
                    if (typeof pointArray !== 'undefined') {
                        var points = [];
                        for (var n = 0; n < pointArray.length; n++) {
                            points.push(new MapInterface.PointInfo(pointArray[n], null, {computed: true}));
                        }

                        for (var j = 0; j < points.length; j++) {
                            this._mapModel.linePoints.splice(markerProperties.prevKeyPointIndex + 1 + j, 0, points[j]);
                        }
                    }

                    markerProperties.linePointIndex = linePointIndex + 1;

                    this._redrawKeyPoints();
                    this._mapEditedFuncs();
                };
            } else {
                // normal, move with waypoint
                if (this._mapModel.linePoints[markerProperties.prevKeyPointIndex]) {
                    startingPoint = this._mapModel.linePoints[markerProperties.prevKeyPointIndex].latLng;
                }
                if (this._mapModel.linePoints[markerProperties.nextKeyPointIndex]) {
                    endingPoint = this._mapModel.linePoints[markerProperties.nextKeyPointIndex].latLng;
                }
                waypoint = thisLinePoint.latLng;
                callback = (pointArray) => {
                    if (typeof pointArray !== 'undefined') {
                        var createdReplacementPoint = false;
                        var wasCreatedReplacementPoint = false;
                        for (var routePointIndex = 0; routePointIndex < pointArray.length; routePointIndex++) {
                            var numberToOverwrite = 0;
                            var newKeypoint = null;
                            var newLinePointIndex = markerProperties.prevKeyPointIndex + 1 + routePointIndex;
                            if (routePointIndex > (pointArray.length / 2) && !createdReplacementPoint) {
                                newKeypoint = {
                                    type: 'Feature',
                                    properties: {
                                        linePointIndex: newLinePointIndex,
                                        draggable: this._mapModel.editMode
                                    },
                                    geometry: {
                                        type: 'Point',
                                        coordinates: [pointArray[routePointIndex].lng, pointArray[routePointIndex].lat]
                                    }
                                };

                                createdReplacementPoint = true;
                            }
                            if (routePointIndex === pointArray.length - 1) {
                                numberToOverwrite = 1;
                                // newLinePointIndex marker automatically removed on next redraw
                            }
                            var isComputed = true;
                            if (createdReplacementPoint && !wasCreatedReplacementPoint) {
                                isComputed = false;
                                wasCreatedReplacementPoint = true;
                            }
                            this._mapModel.linePoints.splice(newLinePointIndex, numberToOverwrite, new MapInterface.PointInfo(pointArray[routePointIndex], newKeypoint, {computed: isComputed}));
                        }
                    }

                    this._redrawKeyPoints();
                    this._mapEditedFuncs();
                };
            }

            this._getSnapToRoadsRoute(
                startingPoint,
                endingPoint,
                waypoint,
                callback
            );
        } else {
            this._redrawKeyPoints();
            this._mapEditedFuncs();
        }
    };

    _getNextKeyPointIndex = (fromIndex, ignoreComputed) => {
        // discover index of next keypoint
        for (var linePointIndex = fromIndex + 1; linePointIndex < this._mapModel.linePoints.length; linePointIndex++) {
            var thisLinePoint = this._mapModel.linePoints[linePointIndex];
            if (thisLinePoint && (ignoreComputed || !thisLinePoint.computed)) {
                return linePointIndex;
            }
        }

        return fromIndex;
    };

    _getPrevKeyPointIndex = (fromIndex, ignoreComputed) => {
        // discover index of next keypoint
        if (fromIndex !== 0) {
            for (var linePointIndex = fromIndex - 1; linePointIndex >= 0; linePointIndex--) {
                var thisLinePoint = this._mapModel.linePoints[linePointIndex];
                if (thisLinePoint && (ignoreComputed || !thisLinePoint.computed)) {
                    return linePointIndex;
                }
            }
        }

        return fromIndex;
    };

    _clearKeyPoints = (options) => {
        options ||= {};

        for (var linePointIndex = 0; linePointIndex < this._mapModel.linePoints.length; linePointIndex++) {
            if (options.keepStartStop) {
                if (linePointIndex === 0 || linePointIndex === this._mapModel.linePoints.length - 1) {
                    continue;
                }
            }

            var thisLinePoint = this._mapModel.linePoints[linePointIndex];
            if (thisLinePoint && thisLinePoint.keyPoint !== null) {
                thisLinePoint.keyPoint = null;
            }
        }

        if (options.clearAll) {
            this.resetMapSource('arrow-marker');
            this.resetMapSource('ghost-marker');
            this.resetMapSource('step-marker');
            this.resetMapSource('pause-marker');
            this.resetMapSource('resume-marker');

            this.resetMapSource('start-marker');
            this.resetMapSource('finish-marker');
        }
    };

    _addKeyPointAtClickLoc = (coordinates) => {
        // Prevent bubbling click on marker and on map
        if (this._mapModel.markerEventClicked) {
            this._mapModel.markerEventClicked = false;
            return;
        }

        if (this._model.searchMarker) {
            this._model.searchMarker.remove();
        }

        if (this._mapModel.hasPopup) {
            this._mapModel.hasPopup = false;
            return;
        }

        // Setup a snapshot to revert to
        this._pushUndoSnapShot();

        // ensure that we aren't in the process of dragging a point or computing a path
        if (this._mapModel.isComputingRoad) {
            return;
        }

        // create new point, add to map, redraw the trip line

        // we don't want to use the routing api if it's only the first point on the map (there's no beginning / end)
        var firstPoint = false;
        if (this._mapModel.linePoints.length === 0) {
            firstPoint = true;
        }
        var secondPoint = false;
        if (this._mapModel.linePoints.length === 1) {
            secondPoint = true;
        }

        if (this.hasModule('TOOLBAR')) {
            if (firstPoint) {
                this.activateToolbarButtons();
            } else {
                this.activateBackToHome();
            }
        }

        if (this.hasModule('HELPER') && this.plugHelperEvents) {
            if (firstPoint) {
                this.displayMapHelper(window.screen.width < 1024
                        ?
                        I18n.t('js.maps.helpers.mobile.draw_route')
                        :
                        I18n.t('js.maps.helpers.desktop.draw_route')
                    , {limitDisplay: true});
            } else if (secondPoint) {
                this.displayMapHelper(window.screen.width < 1024
                        ?
                        I18n.t('js.maps.helpers.mobile.remove_point')
                        :
                        I18n.t('js.maps.helpers.desktop.remove_point')
                    , {limitDisplay: true});
            } else {
                this.hideHelpers();
            }
        }

        // need to remember where in the linePoints array we're adding to, so we can prep the undo function
        // var prevEndingIndex = this._mapModel.linePoints.length;

        // create any points involved, add to linePoints array
        if (this._mapModel.snapToRoads && !firstPoint) {
            this._getSnapToRoadsRoute(
                this._mapModel.linePoints[this._mapModel.linePoints.length - 1].latLng,
                coordinates,
                null,
                (pointArray) => {
                    if (Array.isArray(pointArray)) {
                        for (var pointIndex = 0; pointIndex < pointArray.length; pointIndex++) {
                            this._mapModel.linePoints.push(new MapInterface.PointInfo(pointArray[pointIndex], null, {computed: pointArray.length - 1 !== pointIndex}));
                        }
                    } else {
                        // error, default to normal line behavior
                        this._mapModel.linePoints.push(new MapInterface.PointInfo(coordinates, null, {computed: false}));
                    }

                    this._redrawKeyPoints();
                    this._mapEditedFuncs();

                    if (this.hasModule('RIDE_STORE')) {
                        this.storeRide(this._mapModel.linePoints);
                    }
                }
            );
        } else {
            this._mapModel.linePoints.push(new MapInterface.PointInfo(coordinates, null, {computed: false}));
            this._redrawKeyPoints();
            this._mapEditedFuncs();

            if (this.hasModule('RIDE_STORE')) {
                this.storeRide(this._mapModel.linePoints);
            }
        }
    };

    _pushUndoSnapShot = () => {
        var i;

        var snapShot = [];
        for (i = 0; i < this._mapModel.linePoints.length; i++) {
            var thisLinePoint = this._mapModel.linePoints[i];
            snapShot.push(new MapInterface.PointInfo(
                thisLinePoint.latLng,
                null,
                {
                    computed: thisLinePoint.computed,
                    deltaDistance: thisLinePoint.deltaDistance,
                    deltaTime: thisLinePoint.deltaTime,
                    deltaPause: thisLinePoint.deltaPause,
                    datetime: thisLinePoint.datetime,
                    elevation: thisLinePoint.elevation,
                    speed: thisLinePoint.speed,
                    heartRate: thisLinePoint.heartRate
                }
            ));
        }
        this._mapModel.snapShotStack.push(snapShot);
    };

    _revertToSnapshot = (snapShotIndex) => {
        var i;
        var numberToPop;

        if (typeof snapShotIndex === 'undefined' || snapShotIndex == null) {
            snapShotIndex = this._mapModel.snapShotStack.length - 1;
        }

        if (snapShotIndex < 0) {
            return;
        }

        this._clearKeyPoints({clearAll: true});

        this._mapModel.linePoints = this._mapModel.snapShotStack[snapShotIndex] || [];

        numberToPop = this._mapModel.snapShotStack.length - snapShotIndex;
        for (i = 0; i < numberToPop; i++) {
            this._mapModel.snapShotStack.pop();
        }

        this._redrawKeyPoints();

        if (!this._mapModel.linePoints.length) {
            this.resetMapSource('start-marker');
        } else if (this._mapModel.linePoints.length === 1) {
            this._redrawTripLine();
        }

        this._mapEditedFuncs();
    };

    // takes in 2 points and a stopover
    _getSnapToRoadsRoute = (startPointLatLng, endPointLatLng, wayPointLatLng, callback) => {
        if (!startPointLatLng || !endPointLatLng) {
            return;
        }

        var distanceBetweenPoints = this.distHaversine(startPointLatLng, endPointLatLng);

        var totalDistance = this._mapModel.distance * 1000 + distanceBetweenPoints;
        if (totalDistance >= this._model.mapMaxDistance) {
            this.leaveFullscreen();

            if (this.alertPopup) {
                this.alertPopup.fire({
                    title: I18n.t('js.maps.errors.distance.title'),
                    text: I18n.t('js.maps.errors.distance.text', {distance: parseInt(this._model.mapMaxDistance) / 1000}),
                    icon: 'warning'
                });
            }

            return;
        }

        if (this._model.mapMaxDistance && totalDistance >= this._model.mapMaxDistance) {
            this.leaveFullscreen();

            if (this._model.showProductPopup) {
                this._model.showProductPopup('max_distance');
            }

            return;
        }

        // Limit road computation usage
        if (this._model.hasMapLimitations && this._model.mapMaxRouteComputationCount) {
            var mapMaxRouteComputationCount = StorageService.getItem('map_max_route_computation_count') || 0;

            mapMaxRouteComputationCount += 1;

            if (mapMaxRouteComputationCount > this._model.mapMaxRouteComputationCount) {
                if (this._mapModel.snapToRoads) {
                    AnalyticsService.trackMapDistanceMaxReached();

                    if (this._model.showProductPopup) {
                        this._model.showProductPopup('max_route_computation');
                    }
                }

                if (this.hasModule('TOOLBAR')) {
                    this.disableSnapToRoad();
                }

                callback();

                return;
            }

            StorageService.setItem('map_max_route_computation_count', mapMaxRouteComputationCount);
        }

        this._mapModel.isComputingRoad = true;
        // Computation time is very low, so it's useless
        // this.toggleLoader(I18n.t('js.maps.toolbar.snap_to_road.computing'));

        var pointsArray;

        if (wayPointLatLng !== null && typeof wayPointLatLng !== 'undefined') {
            pointsArray = [startPointLatLng, wayPointLatLng, endPointLatLng];
        } else {
            pointsArray = [startPointLatLng, endPointLatLng];
        }

        this._mapModel.currentRoutingService ||= this.selectRoutingService(pointsArray[0]);

        this._fetchRoute(pointsArray, callback, this._mapModel.currentRoutingService);

        // Cancel if no results
        setTimeout(() => {
            this._mapModel.isComputingRoad = false;
            this.toggleLoader();
        }, 8000);
    };

    _fetchRoute = (pointsArray, callback, routingService) => {
        MapRequest.getDirectionsAPIUrl(pointsArray, routingService, this._mapModel.routingRouteType, {timeout: fetchTimeout})
            .then((data) => {
                this._mapModel.routingService = routingService;

                if (data && (data.errors === 'Bad Request' || (typeof data.errors === 'string' && data.errors.startsWith('Cannot find point'))) && routingService !== MapInterface.routingService.MAPBOX) {
                    AnalyticsService.trackRoutingError(data.errors);
                    this._mapModel.currentRoutingService = MapInterface.routingService.MAPBOX;
                    this._fetchRoute(pointsArray, callback, this._mapModel.currentRoutingService);
                    return;
                }

                if (!data || data.errors) {
                    if (data?.errors) {
                        AnalyticsService.trackRoutingError(data.errors);
                    }

                    Notification.error(I18n.t('js.maps.toolbar.snap_to_road.error'));

                    callback();

                    return;
                } else if (data.abort) {
                    Notification.error(I18n.t('js.maps.toolbar.snap_to_road.network_error'));

                    callback();

                    return;
                }

                var encodedPoints;
                if (routingService === MapInterface.routingService.MAPBOX) {
                    // loop through routes
                    encodedPoints = data.routes.length > 0 ? data.routes[0].geometry : null;
                } else {
                    encodedPoints = data.paths.length > 0 ? data.paths[0].points : null;
                }

                if (!encodedPoints) {
                    Notification.error(I18n.t('js.maps.toolbar.snap_to_road.error'));
                    callback();
                    return;
                }

                var thisRoute = [];
                var decodedArray = Polyline.decode(encodedPoints);
                // loop through steps, create latlng, add
                for (var j = 0; j < decodedArray.length; j++) {
                    thisRoute.push({
                        lat: decodedArray[j][0],
                        lng: decodedArray[j][1]
                    });
                }

                callback(thisRoute);
            })
            .catch((error) => {
                pushError(error);

                Notification.error(I18n.t('js.maps.toolbar.snap_to_road.network_error'));

                callback();
            })
            .finally(() => {
                setTimeout(() => {
                    this._mapModel.isComputingRoad = false;
                    this.toggleLoader();
                }, 100);
            });
    };

    _mapEditedFuncs = () => {
        for (var i = 0, n = this._mapModel.mapEdited.length; i < n; i++) {
            this._mapModel.mapEdited[i](); //let callback know
        }
    };

    _fitMapBounds = (options = {}) => {
        if (!this._model.map) {
            return;
        }

        // get the map bounds by looking at all polylines involved
        if (this._model.mapBounds !== null) {
            return;
        }

        var bounds = this._buildBounds();

        // Fit to polylines or clusters
        if (this._mapModel.linePoints.length > 0) {
            this._mapModel.linePoints.forEach((point) => {
                bounds = bounds.extend([point.latLng.lng, point.latLng.lat]);
            });
        }

        if (Utils.isPresent(this._model.includeBounds) && this._model.includeBounds.length === 4) {
            var bbox = [
                [
                    parseFloat(this._model.includeBounds[1]),
                    parseFloat(this._model.includeBounds[0])
                ],
                [
                    parseFloat(this._model.includeBounds[3]),
                    parseFloat(this._model.includeBounds[2])
                ]
            ];
            bounds = bounds.extend(bbox);
        }

        if (this.hasModule('RIDE_POIS') && this.fitRidePoisBounds) {
            this.fitRidePoisBounds(bounds);
        }

        // fit to the overall bounds
        var fitBoundsOptions = {
            // Prevent map zoom on initial map loading
            zoom: this._model.minZoom,
            padding: this._mapModel.initialPoints || options.withPois ? 150 : 20
        };

        this._model.mapBounds = bounds;
        this.fitBounds(this._model.mapBounds, fitBoundsOptions);

        // Set minimum zoom
        if (this._model.map.getZoom() > (this._model.minZoom || 16)) {
            this._model.map.setZoom(this._model.minZoom || 16);
        }
    };

    _onMapStyleChanged = () => {
        this.addMapTrackLine(this._model.mapTrackLineName);

        // this._redrawTripLine();

        this._redrawKeyPoints();

        // this._drawDirectionArrows();
    };
}
